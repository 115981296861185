import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";
import AnchorLink from 'react-anchor-link-smooth-scroll';

// images
import banner_img from "../images/kundfaktura_banner.svg";
import safe_icon from "../images/skicka_faktura_safe.png";
import miljo_icon from "../images/skicka_faktura_miljo.png";
import spara_icon from "../images/skicka_faktura_spara.png";

import e_faktura_img from "../images/e_faktura.png";
import print_tjanst_img from "../images/skicka_faktura_print_tjanst.png";

const BOT_CODE = "l8QZLG6U";
const BOT_CODE_DEMO = "WfgujSpL";

function Kundfakturor() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [chatbot_boka_demo_1, set_chatbot_boka_demo_1] = useState(false);
    const [chatbot_boka_demo_2, set_chatbot_boka_demo_2] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Kundfakturor | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Skicka kundfakturor enkelt och effektivt oavsett format." />
                <meta name="keywords" content="utgående fakturor | Kundfakturor" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Skicka kundfakturor enkelt och effektivt oavsett format." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/kundfakturor" />
                <link rel="cannonical" href="https://www.crediflow.se/kundfakturor" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Kundfakturor</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Vem du än vill skicka till och oavsett format 
                                        erbjuder vi ett enkelt och effektivt sätt att 
                                        hantera dina utgående fakturor.
                                        <br/><br/>
                                        Vår tjänst tar hand om alla era utgående fakturor 
                                        och ser till att de når mottagaren på det sättet de 
                                        vill och kan ta emot dem, E-faktura, PDF via mail 
                                        eller distribution via post. Från dag ett digitaliserar 
                                        och effektiviserar vi din hantering samtidigt som vi 
                                        arbetar proaktivt mot effektivare fakturaformat 
                                        och ytterligare effektivisering
                                        <br/><br/>
                                        Kanske har er kund bett er skicka en EDI faktura 
                                        men ni kan inte det? Troligen så menar din 
                                        kund e-faktura men säger EDI. Låt oss 
                                        hjälpa er.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-2/3 lg:w-full p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="hantera utgående fakturor" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">kundfakturor</span>
                    </div>
                    <div className="grid md:grid-cols-3 gap-8">
                        <AnchorLink href="#skickaFaktura_article">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={safe_icon} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Fakturan når mottagaren direkt och 
                                        utan risk för att den försvinner på vägen.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#skickaFaktura_article">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={spara_icon} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Fakturan är enklare för mottagaren att 
                                        hantera vilket gör att du får betalt korrekt och i tid.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#skickaFaktura_article">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={miljo_icon} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Är miljöperspektivet viktigt för 
                                        ditt företag är e-faktura det självklara valet.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 pt-36 pb-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>




            <section className="bg-gray-100" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">E-faktura &#8211; Bäst för dig och dina kunder</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Förenkla för både dig själv och dina kunder genom att 
                                skicka e-faktura. Förutom att det sedan april 2019 
                                är lag på att skicka e-faktura till offentlig sektor 
                                ser vi också idag att e-fakturering allt oftare 
                                är ett krav vid upphandlingar. Även om e-faktura 
                                har en transaktionskostnad blir den kostnadsbesparande 
                                för hela organisationen då den effektiviserar administrationen.
                            </p>
                            <div className="mt-10 flex">
                                <button onClick={() => set_chatbot_boka_demo_1(true)} className="px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                                <Link to="/leverantorsfakturor/e_faktura" className="ml-3 px-10 py-2 rounded-full text-blue-custome border border-blue-custome bg-white shadow-md hover:bg-blue-100">Läs mer</Link>
                            </div>
                            
                            {chatbot_boka_demo_1 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE_DEMO} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={e_faktura_img} alt="Skicka e fakura" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={print_tjanst_img} alt="Skicka e fakura" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Printtjänst  &#8211; Ett onödigt arbete vi gör åt dig till en lägre kostnad</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Även om digitala format ökar så behöver företag skicka 
                                såväl fakturor som annan information via post. Genom 
                                att nyttja vår tjänst slipper du lägga tid på utskrifter, 
                                kuvertering, postinlämning med mera dessutom blir det 
                                billigare än att du gör det själv.
                                <br/><br/>
                                Med vår printtjänst når du dina mottagares fysiska 
                                brevlåda med ett par klick. Oavsett vad du vill 
                                skicka: Fakturor, informationsbrev, lönespecifikationer, reklam.
                            </p>
                            <button onClick={() => set_chatbot_boka_demo_2(true)} className="px-7 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                            {chatbot_boka_demo_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE_DEMO} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}
export default Kundfakturor;